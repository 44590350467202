import './App.css';
import { useEffect, useState } from 'react';

function App() {
  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    const handleResize = () => {
      setVh(window.innerHeight * 0.01);
    };

    // Set initial vh value
    setVh(window.innerHeight * 0.01);

    // Update vh on resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const firstPart = "Surfacing ";
  const secondPart = "soon...";
  
  return (
    <div className="App" style={{ height: `${vh * 100}px`}}>
      <header className="App-header" aria-label={`${firstPart} ${secondPart}`}>
        <p className="bubble-text" aria-label={`${firstPart} ${secondPart}`}>
          <span className="bubble-line">
            {firstPart.split("").map((letter, index) => (
              <span key={index} className="bubble-letter" aria-hidden="true">
                {letter === " " ? "\u00A0" : letter} {/* Handle spaces properly */}
              </span>
            ))}
          </span>
          <span className="bubble-line">
            {secondPart.split("").map((letter, index) => (
              <span key={index} className="bubble-letter" aria-hidden="true">
                {letter === " " ? "\u00A0" : letter} {/* Handle spaces properly */}
              </span>
            ))}
          </span>
        </p>
      </header>
      <div className="bubbles">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        {/* You can add more bubble divs as needed */}
      </div>
    </div>
  );
}

export default App;
